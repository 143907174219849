import React from 'react';
import Layout from '../components/layout/layout';
import * as styles from '../components/blog.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Commentsimg from '../images/comments.svg';
import ProfileImg from '../images/profile.svg';
import BlogImg from '../images/mechen.jpg';
import Facebook from '../images/facebookBlue.png';
import Twitter from '../images/twitterBlue.png';
import Linkedin from '../images/linkedinBlue.png';
import MailBlue from '../images/mailBlue.png';
import CardsRowImg1 from '../images/cardsRowImg1.png';
import CardsRowImg2 from '../images/cardsRowImg2.png';
import CardsRowImg3 from '../images/cardsRowImg3.png';
import { FacebookProvider, Comments } from 'react-facebook';

class BradenComingMechen extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  componentDidMount(){
    const fbSDK = (d, s, id) => {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=691895914788460';
      fjs.parentNode.insertBefore(js, fjs);
    };
    fbSDK(document, 'script', 'facebook-jssdk');
    window.FB.XFBML.parse();
  }
  // componentDidMount() {
  //   window.fbAsyncInit = function() {
  //   window.FB.init({
  //     appId: "691895914788460",
  //     autoLogAppEvents : true,
  //     xfbml            : true,
  //     version          : 'v3.0'
  //    });
  //   };
  
  //   // Load the SDK asynchronously
  //   (function(d, s, id) {
  //    var js,
  //      fjs = d.getElementsByTagName(s)[0];
  //    if (d.getElementById(id)) return;
  //    js = d.createElement(s);
  //    js.id = id;
  //    js.src = "//connect.facebook.net/en_US/sdk.js";
  //    fjs.parentNode.insertBefore(js, fjs);
  //  })(document, "script", "facebook-jssdk");
  // }  
  
  // componentDidUpdate() {
  //    window.FB.XFBML.parse();
  //  }
  render(){
    return(
      <Layout>
        <div className={styles.landingSection}>
          <div className={styles.landingSectionSubDiv}>
            <text className={styles.blogHeader}>Braden Coming to McMechen to Promote Virtual Reality Racing</text>
            <div className="row" id={styles.subtitle}>
              <div className={styles.profileCol2}>
                <img className={styles.ProfileImg} src={ProfileImg} alt="landingimg" />
                <text className={styles.profileTest}>By Bubba Kapral</text>
                <p>06 Aug, 2019</p>
              </div>
              <div className={styles.profileCol2} id={styles.profilecolId}>
                <img className={styles.ProfileImg} src={Commentsimg} alt="landingimg" />
                <text className={styles.profileTest}>0 Comments</text>
              </div>
            </div>
            <img className={styles.blogImg} src={BlogImg} alt="landingimg" />
            <text className={styles.blogDesc}>
              <p>WHEELING — Travis Braden is a rising star in the world of race cars. The Wheeling native is 25 years old and is already a two-time ARCA/CRA Super Series champion.</p>
              <p>Braden is also a major player in a movement that would change the eSports landscape in West Virginia. Possible legislation is in the talking stages in the Mountain State on whether to adopt virtual reality betting in restaurants and bars.</p>
              <p>Braden is championing the cause throughout the state. This coming weekend he will be in McMechen at Jerry’s Bar to promote virtual reality racing. Jerry’s Bar is owned by Jake Padlow, who is also the co-founder, along with Dave Ealy Jr., of PropMe LLC, an online gaming company.</p>
              <p>Virtual reality gaming will allow for unique betting options via an on-line game app on your cell phone. It enables peer-to-peer betting by finding a prop and sending it directly to another user. It is also possible to have one-to-many betting by finding a prop then choosing the amount of the bet and the number of challengers, then place it in the marketplace.</p>
              <p>PropMe has partnered with Saber VR out of New Jersey for eSports and virtual gaming. The two have joined forces to present the Virtual Reality SIM Racing Weekend running Friday through Sunday in McMechen.</p>
              <p>Braden will be at Jerry’s Bar along with his actual race car at the event and will be on hand Friday from noon-6 p.m. The event will also feature two race simulators. On Saturday, those simulators will be available for individuals to test their racing skills beginning at noon. On Sunday, action begins at noon with Braden taking on all challengers on the racing simulators.</p>
              <p>Virtual reality betting would not be limited to race cars. Padlow said it offers many options such as boxing, hunting, fishing etc.</p>
              <p>“We have the ability to install 35 simulators throughout the state. Different establishments would have different game options, so not to take away from each other,” Padlow said. “This has been in the works for a long time. It is a neat setup. People can bet one-on-one or enter tournaments statewide. Travis is a big supporter of this.”</p>
              <p>PropMe and other establishments aim to offer new and easy accessible gambling experiences via virtual reality betting. It is a concept being broached with West Virginia officials.</p>
              <p>“We have been talking to some lawmakers and lobbyists. They definitely like the idea,” Padlow said. “The tracks are attempting to get their satellite casino this year, so we think it is a perfect time for family owned and operated bars and restaurants to get a small sports book along with eSports and virtual reality. We hope anyone who is a fan of racing or eSports will come and check this out.”</p>
              <h4>BUBBA’S BITS</h4>
              <p>∫ Former St. John Central three-sport standout Kristen Russell has been hired as the athletic trainer for the Miami of Ohio women’s volleyball team.</p>
              <p>∫ Steubenville native Chaz Roe is enjoying a solid season out of the Tampa Bay Rays bullpen. The former Kentucky High prep prodigy has appeared in 42 games thus far, compiling a 1-3 mark with one save. He has fanned 42 batters in 34 innings. Roe is the son of former Buckeye North and University of Kentucky grid star Donnie Roe. Chaz’s uncle, Jim Hoover, another Buckeye North grid standout, teaches and coaches at Martins Ferry High. Former Buckeye South three-sport great and OVAC Hall of Famer Stan Boroski is Tampa Bay’s bullpen coach while Steubenvillle product Paul Hoover is the team’s major league field coordinator.</p>
              <p>∫ It is hard to fathom but every high school in Belmont County will have a new head softball coach in the 2019-2020 academic season.</p>
              <p>∫ Zanesville Bishop Rosecrans High announced last month it was scrapping its 11-man football program to go with an eight-man team. The move came about when only 13 players signed up to play football. Now a second Ohio high school is going a similar route. Sandusky St. Mary Central Catholic opted for the eight-man team this past Monday. That being said, 713 schools will field varsity football teams this season in Ohio.</p>
              <p>∫ Houston and Cleveland were the big winners in baseball’s trading sweepstakes. The Astros adding Zack Greinke now have a killer three-stud starting rotation for the playoffs, joining Justin Verlander and Gerrit Cole. The Indians did well parting ways with Trevor Bauer while getting Yasiel Puig, Scott Moss, Franmil Reyes, Logan Allen and Victor Nova in return.</p>
              <p>∫ Magnolia High School’s Jacob Koontz placed 10th in the ninth grade division in the National Archery Championships in Nashville last month. Overall, Koontz placed 42nd out of 1,280 male shooters. That is an impressive performance.</p>
              <p>∫ Our thoughts and prayers go out to the family of Monte Miller. The former Bellaire High and St. Clairsville High track coach died Wednesday. He was 72. Monte was a good friend, tremendous coach and a person who lived life to its fullest. This past June he was inducted into the Eastern District Cross Country & Track Hall of Fame, a very deserving honor.</p>
              <p>∫ John Cominsky performed quite well for the Atlanta Falcons in their preseason opener against Denver Thursday. The rookie defensive end was a fourth-round pick out of the University of Charleston after being named Mountain East Conference Defensive Player of the Year. His dad, Steve Cominsky, is a 1989 Buckeye SouthWest graduate, now Buckeye Local.</p>
              <p>Bubba Kapral can be reached at bkapral@timesleaderonline.com</p>
              <p>Source:  <a href="https://www.theintelligencer.net/sports/top-sports/2019/08/braden-coming-to-mcmechen-to-promote-virtual-reality-racing/" target="_blank">http://www.theintelligencer.net/sports/top-sports/2019/08/braden-coming-to-mcmechen-to-promote-virtual-reality-racing/</a></p>
            </text>
            <div className={styles.oldPostsLink}>
            <a className={styles.servicelinks} href="/braden-helps-promote-virtual-racing"><text>{'<'}Older Post</text></a>
            <a className={styles.servicelinks} href="/propme-signs-exclusive-deal"><text>Newer Post{'>'}</text></a>
            </div>
            <div className={styles.BlogsocialLinksDiv}>
              <img className={styles.social} src={Facebook} alt="landingimg" />
              <img className={styles.social} src={Twitter} alt="landingimg" />
              <img className={styles.social} src={Linkedin} alt="landingimg" />
              <img className={styles.social} src={MailBlue} alt="landingimg" />
            </div>
            <div id="fb-root"></div>
            {/* <div className="fb-comments" data-href={'https://friendly-curran-11b640.netlify.app/' + this.props.location.pathname}
                 data-colorscheme="dark" data-width="100%" data-numposts="2" data-order-by="reverse_time"></div> */}
            {/* <div class="fb-comments" data-href="https://friendly-curran-11b640.netlify.app/blog/" data-width="1000" data-numposts="5"></div> */}
            {/* <FacebookProvider appId="691895914788460">
              <Comments href="https://www.facebook.com/mcmechenBlog" data-width="100%"/>
            </FacebookProvider>
            <iframe src="https://www.facebook.com/plugins/comments.php?href=https://friendly-curran-11b640.netlify.app/mcmechenBlogs/" scrolling="no" frameBorder="0" allowtransparency="true"></iframe>
            <div className="fb-comments" data-href="https://friendly-curran-11b640.netlify.app/braden-coming-to-mcmechen" data-width="100%" data-numposts="10"></div> */}
            <iframe name="f297624ad60196" width="500px" height="100px" data-testid="fb:comments Facebook Social Plugin" title="fb:comments Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.0/plugins/comments.php?app_id=691895914788460&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df247eedd96682d4%26domain%3Dfriendly-curran-11b640.netlify.app%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ffriendly-curran-11b640.netlify.app%252Ffe72a2fa7cef6c%26relation%3Dparent.parent&amp;color_scheme=light&amp;container_width=714&amp;height=100&amp;href=https%3A%2F%2Fwww.facebook.com%2Fblahblah%2F&amp;locale=en_US&amp;numposts=6&amp;sdk=joey&amp;version=v2.0&amp;width=500" style={{border: 'none', visibility: 'visible', width: '500px', height: '458px'}} class=""></iframe>
            <div className={styles.featuredNewsDiv}>
              <text>Featured News</text>
            </div>
            </div>
            <div className="row">
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowImg1} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>PropMe’s Peer-to-Peer Marketplace</text>
                    </div>
                    <text className={styles.partnerDesc}><p>Overview. Sports wagering in America received its wings on May 14, 2018 when the United States Supreme… Court ruled that the Professional and Amateur Sports Protection Act (PASPA), the 1992 federal law that hadPeer-to-Peer Marketplace</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <div className={styles.continueDiv}>
                    {/* <a className={styles.servicelinks} href="/lmschain">LMSCHAIN</a> </p>
                    <p>SAP Blog: <a className={styles.servicelinks} href="#">here</a> */}
                    <a className={styles.servicelinks} href="/propmes-peer-to-peer-marketplace"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowImg2} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>Braden Wins the Snowball</text>
                    </div>
                    <text className={styles.partnerDesc}><p>It was the most improbable finish of his career. It was wild and it was staggering, several times over. But if you’ve paid close attention, you could’ve seen this coming, because it was also methodical and surgical. It was Travis Braden showing how it’s done.</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <div className={styles.continueDiv}>
                    <a className={styles.servicelinks} href="/braden-wins-the-snowball"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4" id={styles.whatDivCol}>
              <div className={styles.cardsRow1Col}>
                <div className={styles.partners1}>
                  <img className={styles.partners1Img} src={CardsRowImg3} alt="hugesuccess" />
                  <div className={styles.contentsdiv}>
                    <div className={styles.partners1headerMain}>
                      <text className={styles.partners1header}>Braden Helps Promote Virtual Racing</text>
                    </div>
                    <text className={styles.partnerDesc}><p>MCMECHEN — Pro race car driver and Wheeling native Travis Braden grew up wishing that video game technology... would be more realistic — especially when it came to racing. That time has come.</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                    <div className={styles.continueDiv}>
                    <a className={styles.servicelinks} href="/braden-helps-promote-virtual-racing"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </Layout>
    )
  }
}

export default BradenComingMechen